import React, { useState, useEffect, useRef } from "react"
import { navigate } from 'gatsby-link'
import { Link } from "gatsby"
import toast from "react-hot-toast"
import SimpleReactValidator from "simple-react-validator"
import { useLocation } from '@reach/router'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Layout from "../layout/layout"
import Breadcrumbs from "../addon/breadcrumbs"
import Meta from "../addon/meta"
import Modal from "react-bootstrap/Modal"

import useScript from '../../service/useScript'
import API from "../../api/user"
import UPLOAD_API from "../../api/postings"
import PAYMENT_API from "../../api/payment"
import { acceptOnlyNumbers, formatPaymentDateTime, formatTodayDateTime, generateUniqueId } from "../../service/helper"
import PaymentBilling from "../addon/paymentBilling/billing"

import { PAYMENT, BILLDESK_MERCHANT_ID, BILLDESK_SECURITY_ID } from '../../service/config'
import LinkButton from "../addon/button/linkButton"
import APIButton from "../addon/button/apiButton"
import UseCustomValidator from "../../service/simpleValidator"

const Register = () => {

    const location = useLocation()
    const [personalDetailsValidated, setPersonalDetailsValidated] = useState(false)
    const [contactDetailsValidated, setContactDetailsValidated] = useState(false)
    const [disableStates, setDisableStates] = useState(true);
    const [otp, setOTP] = useState(null);
    const [lang, setLang] = useState('english');
    const [verifyOpen, setVerifyOpen] = useState(null);
    const [otpLoading, setOTPLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const { customMobileValidator } = UseCustomValidator()
    const [showModal, setShowModal] = useState(false)
    const [registrationType, setRegistrationType] = useState('normal')
    const [show, setShow] = useState(false);
    
    const [error, setError] = useState({
        mobileError: "",
        otpError: ""
    })

    let panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    let aadharRegex =/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;

    const [personalDetailValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => <p className="field_error" > {message} </p>,
        validators: {
            validateAadhar: {
                message: 'The :attribute should be a valid aadhar no.',
                rule: (val, params, validator) => {
                    if(aadharRegex.test(val)) {
                        return val
                    } else {
                        return false
                    }
                },
                messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
                required: false  // optional
            },
            validatePan: {
                message: 'The :attribute should be a valid pancard no.',
                rule: (val, params, validator) => {
                    if(panRegex.test(val)) {
                        return val
                    } else {
                        return false
                    } 
                },
                messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
                required: false  // optional
            },
        }
    })))

    const [contactDetailValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className = "field_error" > {message} </p>}
    )))
    const kinDetailValidator = customMobileValidator
    const [mobileValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className = "field_error" > {message} </p>}
    )))
    const [otpValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className = "field_error" >The OTP field is required.</p>}
    )))

    const [, forceUpdateForRegister] = useState();
    const [registerForm, setRegisterForm] = useState({
        RegType: "EX",
        ApptNo: "",
        OnlinePayRefNo: `HNHONLAPT-${generateUniqueId()}`,
        TotalPayment: '',
        title: '',
        PatFirstName: '',
        PatMidName: '',
        PatLastName: '',
        Dob: '',
        birthDate:'',  // for date formating
        Gender: '',
        MaritalStatus: '',
        ReligionCode: '',
        Profession: '',
        PatAdd_1: '',
        PatAdd_2: '',
        City: '',
        PostBoxNo: '',
        NationalityCode: '',
        State: '',
        Tele_resident: '',
        Tele_work: '',
        ABHA_ID: '',
        Mobile: '',
        Email: '',
        KinRelationCode: '',
        KinFirstName: '',
        KinMidName: '',
        KinLastName: '',
        KinTelResident: '',
        KinMobile: '',
        DoctorName: '',
        DoctorTelephone: '',
        Age: '', 
        AgeType: "Y",
        PanNo: '',
        AadhaarNo: '',
        PatSource: "Doctor Referral", 
        registration_type : 'normal'
    });
    const [contact, setContact] = useState({
        PatAdd_1: '',
        PatAdd_2: '',
        City: '',
        PostBoxNo: '',
        NationalityCode: '',
        State: '',
        Country: '',
        Tele_resident: '',
        Tele_work: '',
        Mobile: '',
        OTP : ''
    })
    const [kinDetails, setKinDetails] = useState({
        KinRelationCode: "",
        // KinTitle: "",
        KinFirstName: "",
        KinMidName: "",
        KinLastName: "",
        KinMobile: "",
        KinTelResident: '',
        // DoctorDetails: "",
        DoctorName: "",
        // DoctorRefName: "",
        DoctorTelephone: "",
        teleType: ""
    })
    const [day, setDay] = useState({
        date: "",
        month: "",
        year: ""
    })
    const [checkbox, setCheckbox] = useState({
        all_tc: false,
        agree: false,
        autorize: false
    })
    const [breadcrumbs] = useState([
        { label: 'Home', link: '/' },
        { label: 'Register' }
    ])

    const [religionData, setReligionData] = useState([])
    const [relationData, setRelationData] = useState([])
    const [maritalStatusData, setMaritalStatusData] = useState([])
    const [countryData, setCountryData] = useState([])
    const [stateData, setStateData] = useState([])
    const [appointment, setAppointment] = useState({})
    const [aadharImg, setAadharImg] = useState(null);
    const [fileError, setFileError] = useState('')
    const [total, setTotal] = useState(null)
    const [regCharge, setRegCharge] = useState(null)
    
    const [retriveData, setRetriveData] = useState(null)
    
    const openModal = () => {
        setShowModal(true)
    }
    const closeModal = () => {
        setShowModal(false)
    }

    const handleImgUpload = async (e) => {
        let fileName =  e.target.value.toLowerCase()
        let file = e.target.files[0]
        if (file && fileName.includes('.jpg') 
            || file && fileName.includes('.png') 
            || file && fileName.includes('.pdf')
            || file && fileName.includes('.jpeg')) {
            setFileError("");
            setAadharImg(file)
        } else {
            setFileError("Please upload your Img in .jpg or .png or pdf format")
        }
    }

    function calculateAge(dateString) {
        const x = dateString.split("/");
        let str = ''
        if (navigator.userAgent.indexOf("Win") != -1) {
            str = `${x[0]}/${x[1]}/${x[2]}`
        } else {
            str = `${x[1]}/${x[0]}/${x[2]}`
        }
        var dob = new Date(str);
        var month_diff = Date.now() - dob.getTime();
        var age_dt = new Date(month_diff);  
        var year = age_dt.getUTCFullYear()
        var age = Math.abs(year - 1970);
        if(age === 0 || age) return age;

        // old age logic
        // const from = dateString.split("/");
        // const birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0]);
        // const cur = new Date();
        // const diff = cur - birthdateTimeStamp;
        // // This is the difference in milliseconds
        // const currentAge = Math.floor(diff/31557600000);
        // return currentAge;
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRegisterForm({
            ...registerForm,
            [name]: value,
        });
    };
    const handleContactChange = (e) => {
        const { name, value } = e.target;
        if(name === 'Country') {
            getStatesDataByCountryId(value)
        }
        if(name === 'Mobile' || name === 'PostBoxNo' || name === 'OTP' || name === 'Tele_resident' || name === 'Tele_work' || name === 'ABHA_ID') { 
            let val = acceptOnlyNumbers(e.target.value)
            setContact({
                ...contact,
                [name]: val,
            });
        } else {
            setContact({
                ...contact,
                [name]: value,
            });
        }        
    };
    const handleKinChange = (e) => {
        const { name, value } = e.target;
        if(name === 'KinMobile' || name === 'DoctorTelephone' || name === 'KinTelResident') { 
            let val = acceptOnlyNumbers(e.target.value)
            setKinDetails({
                ...kinDetails,
                [name]: val,
                DoctorId: appointment?.docCode,
                ...(appointment.appointmentType === 'TEL' || appointment.appointmentType === 'PTE' || appointment.appointmentType === 'PHY') && {teleType:'TE'},
                ...(appointment.appointmentType === 'TEL' || appointment.appointmentType === 'PTE' || appointment.appointmentType === 'PHY') && {slotId: appointment?.slotId},
                ...(appointment.appointmentType === 'TEL' || appointment.appointmentType === 'PTE' || appointment.appointmentType === 'PHY') && {ApptDate: appointment?.appointmentDate}
            });
        } else {
            setKinDetails({
                ...kinDetails,
                [name]: value,
                DoctorId: appointment?.docCode,
                ...(appointment.appointmentType === 'TEL' || appointment.appointmentType === 'PTE' || appointment.appointmentType === 'PHY') && {teleType:'TE'},
                ...(appointment.appointmentType === 'TEL' || appointment.appointmentType === 'PTE' || appointment.appointmentType === 'PHY') && {slotId: appointment?.slotId},
                ...(appointment.appointmentType === 'TEL' || appointment.appointmentType === 'PTE' || appointment.appointmentType === 'PHY') && {ApptDate: appointment?.appointmentDate},
                ...(window.location.hash === '#create_hh') && {hhCreateType: 'HHC'}
            });
        }
    };
    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setCheckbox({
            ...checkbox,
            [name]: checked,
        });
    }

    const removeImg = () => {
        setAadharImg(null)
        const el = document.getElementById('reg_img_upload')
        if(el?.value) el.value = ''
    }

    const handlePayment = async () => {
        const res = await UPLOAD_API.submitImage(aadharImg)
        .catch(err => {
            console.log(err)
            toast.error("Image Upload Failed.")
            setLoading(false)
            return
        })
        let imgData = {}
        if(res?._links?.self?.href && res?.filemime?.[0]?.value) {
            imgData = {
                id_proof: res?._links?.self?.href,
                filemime: res?.filemime?.[0]?.value
            }
        } else {
            setLoading(false)
            return
        }
        let json = {
            ...imgData,
            ...registerForm,
            ...contact,
            ...kinDetails
        };
        json.Age = calculateAge(json.Dob);
        json.registration_type = registrationType;

        delete json?.birthDate
        delete json?.title
        json['voucherType'] = 'DCH'

        try {
            let response = await PAYMENT_API.convertJsonDataToId(json)
            if(response) {
                goToPayment(json, response)
            }
        }
        catch(err) {
            console.log(err)
            setLoading(false)
            toast.error("Something went wrong")
        }
    }

    const submitRegistrationForm = async () => {
        let registrationCharges = await PAYMENT_API.getRegCharges()
        setRegCharge(registrationCharges)
        setLoading(true)
        const personalResult = personalDetailValidator.current.allValid()
        const contactResult = contactDetailValidator.current.allValid()
        const mobileResult = mobileValidator.current.allValid()
        let otpResult = otpValidator.current.allValid()
        const kinResult = kinDetailValidator.current.allValid()
        if (personalResult === false) {
            personalDetailValidator.current.showMessages()
            forceUpdateForRegister(1)
        }
        if (contactResult === false) {
            contactDetailValidator.current.showMessages()
            forceUpdateForRegister(1)
        }
        if (mobileResult === false) {
            mobileValidator.current.showMessages()
            forceUpdateForRegister(1)
        }
        if (otpResult === false || contact.OTP?.length === 0) {
            otpValidator.current.showMessages()
            forceUpdateForRegister(1)
            toast.error("Please verify your mobile no.")
            otpResult = false
        }
        if (kinResult === false) {
            kinDetailValidator.current.showMessages()
            forceUpdateForRegister(1)
        }
        
        if (aadharImg === '' || aadharImg === null) {
            toast.error("Please Upload Gov Id Proof")
            forceUpdateForRegister(1)
        }
        setPersonalDetailsValidated(personalResult)
        setContactDetailsValidated(contactResult)
        if (contactResult === true && personalResult === true && kinResult === true && mobileResult === true && otpResult === true) {
            
            if (Object.values(checkbox).indexOf(false) === -1) {
                setLoading(false)
                openModal()
            } else {
                setLoading(false)
                toast.error("Please accept the Terms and conditions before proceeding.")
            }
        } else {
            setLoading(false)
        }
    }

    const goToPayment = (json, response) => {
        let regCharge = regCharge?.Data?.proc_charge || '300'
        console.log('json======>',json)
        let uniqueId = response?.ref_no
        let str = ''
        if(Object.keys(appointment).length === 0 || appointment === ''){
            let todayDateTime = formatTodayDateTime()
            str = `${BILLDESK_MERCHANT_ID}|${uniqueId}|NA|${regCharge}|NA|NA|NA|INR|NA|R|hindujahsp|NA|NA|F|0|${json?.PatFirstName} ${json?.PatLastName}|${todayDateTime}|NA|${uniqueId}|NA|NA|https://admin.hindujahospital.com/api/billdesk/callBackUrl`
        }else{
            str = `${BILLDESK_MERCHANT_ID}|${uniqueId}|NA|${total+'.00'}|NA|NA|NA|INR|NA|R|hindujahsp|NA|NA|F|${appointment?.pin}|${appointment?.pat_name || 'NA'}|${formatPaymentDateTime(appointment?.appointmentDate, appointment?.appointmentTime)}|${appointment?.docCode === 'OTH' ? 'NA' : appointment?.doctorName}|${uniqueId}|${appointment?.proc_des || 'NA'}|${appointment?.appt_no}|https://admin.hindujahospital.com/api/billdesk/callBackUrl`
        }
        
        // let str2 = `${BILLDESK_MERCHANT_ID}|${uniqueId}|NA|${total+'.00'}|NA|NA|NA|INR|NA|R|${BILLDESK_SECURITY_ID}|NA|NA|F|${json?.Email}|${json?.Mobile}|${id}|NA|NA|NA|NA|NA`
        let obj = {
            msg: str,
            callback: PAYMENT.CALLBACK
        }
        navigate(`/payment`,{ state: obj })
    }

    // API Calls
    const getAllReligionData = () => {
        API.getReligions()
        .then(res => {
            if(res?.Data) {
                setReligionData(res?.Data)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }
    const getRelationsData = () => {
        API.getRelations()
        .then(res => {
            if(res?.Data) {
                setRelationData(res?.Data)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }
    const getMaritalStatusData = () => {
        API.getMaritalStatus()
        .then(res => {
            if(res?.Data) {
                setMaritalStatusData(res?.Data)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }
    const getCountryData = () => {
        API.getAllCountry()
        .then(res => {
            if(res?.Data) {
                setCountryData(res?.Data)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }
    const getStatesDataByCountryId = (id) => {
        API.getStatesByCountryId(id)
        .then(res => {
            if(res?.Data) {
                let arr = res?.Data
                arr.sort((a, b) => {
                    if(a?.state_des < b?.state_des) return -1
                    if(a?.state_des > b?.state_des) return 1
                    return 0
                })
                setStateData(arr)
                setDisableStates(false);
            }
        })
        .catch(err => {
            console.log(err)
            setStateData([])
            setDisableStates(true);
        })
    }

    useEffect(() => {
        getAllReligionData()
        getRelationsData()
        getMaritalStatusData()
        getCountryData()
        if(location?.state && location?.state?.formData && location?.state?.apiData) {
            let formData = location.state.formData
            let apiData = location.state.apiData
            setRegisterForm({
                ...registerForm,
                ['PatFirstName']: formData?.Firstname, 
                ['PatLastName']: formData?.Lastname,
                ['Email']: formData?.Email,
                ['ApptNo']: apiData?.appt_no
            });
            setContact({
                ...contact,
                ['OTP']: formData?.otp,
                ['Mobile']: formData?.Mobile,
                ['Country']: formData?.Country,
                ['PATISDCD']: '+'+formData?.MobileCode
            });
            if(formData?.Country) {
                getStatesDataByCountryId(formData?.Country)
            }
            setAppointment({ ...formData, ...apiData })
            setVerifyOpen(true);
        } else {
            if(window.location.hash !== '#create_hh'){
          navigate('/user/login')
                setRegistrationType('normal')
            }else{
                setRegistrationType('HH')
            }
        }
    }, [])

    useEffect(() => {
        if(total) {
            setRegisterForm({
                ...registerForm,
                ['TotalPayment']: total
            });
        }
    },[total])

    useScript("/assets/js/custom_select.js"); 
    useScript("/assets/js/login-register.js");

    const onVerify = () =>{
        const mobileResult = mobileValidator.current.allValid()
        if (mobileResult === false) {
            mobileValidator.current.showMessages()
            forceUpdateForRegister(1)
            setVerifyOpen(false);
        }else{
            setOTPLoading(true)
            API.sendOTP({
                mobileNo : contact?.Mobile,
                email_id : registerForm?.Email,
                "otpFor": "quick_register"
            }).then(response => {
                setVerifyOpen(true);
                toast.success("OTP sucessfully sent on Mobile no and Email id")
                setOTPLoading(false)
            }).catch(err => {
                console.log(err)
                toast.error("Something went wrong. Please try again later")
                setVerifyOpen(false);
                setOTPLoading(false)
            })
        }
    }

    const onResendOTP = () => {
        setOTPLoading(true)
        API.sendOTP({
            mobileNo : contact?.Mobile,
            email_id : registerForm?.Email,
            "otpFor": "quick_register"
        }).then(response => {
            setVerifyOpen(true);
            toast.success("OTP sucessfully sent on Mobile no and Email id")
            setOTPLoading(false)
        }).catch(err => {
            console.log(err)
            setVerifyOpen(false);
            toast.error("Something went wrong. Please try again later")
            setOTPLoading(false)
        })
    }
    
    useEffect(() => {
        if (typeof window.initSelect !== 'undefined') window.initSelect();
    }, [religionData, relationData, countryData, stateData])

    const metaTags = [];

    return (
    <>
        <Layout>
            <Meta
                title="login"
                files={{
                    js: [],
                    css: ["/assets/css/login.css" ,"/assets/css/accessibility.css"]
                }}
                tags={metaTags}
            />
            <main className="innerpage">
                <Breadcrumbs
                    breadcrumbs={breadcrumbs}
                    />
            </main>
            <section className="section_bg login_section pt-first-section">
                <div className="container">
                    <h2 className="section-heading">Register</h2>
                    <div className="row no-gutters">
                        <div className="col-lg-12 col-md-12">
                            <div className="white_rounded_box">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                        <Link to="/user/register" className="nav-link active">Register</Link>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane active" id="tab-register" role="tabpanel">
                                        <div className="form_wrap" id="register_form_wrap">
                                            <div className={`detail_wrap form_block ${personalDetailsValidated ? "active" : ""}`}>
                                            <span className="vertical_line"></span>
                                            <h3>Personal Details login</h3>
                                            {/* Changes OF Consent Start  */}
                                            <div className="formNoteSec">
                                                <p className="noteText"><strong>Note *</strong> :</p>
                                                <ol>
                                                <li>Kindly enter patient details</li>
                                                <li>For this registration, patient name and age should be as per government ID</li>
                                                <li>Do not fill this form if the patient has a HH number from P.D.Hinduja Hospital & Medical Research Center</li>
                                                </ol>
                                            </div>   
                                             {/* Changes OF Consent End  */}
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-2 col-6">
                                                            <div className="form-group select-dd">
                                                                <label>Title</label>
                                                                <select onChange={handleInputChange} value={registerForm.title} name="title" id="title" className="form-control custom-select" placeholder="Title">
                                                                    <option hidden>Choose Title</option>
                                                                    <option value="Mr">Mr.</option>
                                                                    <option value="Miss">Miss.</option>
                                                                    <option value="Dr">Dr.</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-9">
                                                            <div className={`form-group -animated -active`}>
                                                                <label>First Name <span className="mandatory">*</span></label>
                                                                <input
                                                                    onChange={handleInputChange}
                                                                    id="PatFirstName"
                                                                    name="PatFirstName"
                                                                    disabled={false}
                                                                    value={registerForm.PatFirstName}
                                                                    className="form-control"
                                                                    type="text"
                                                                    autoComplete="nope"
                                                                    />
                                                                {personalDetailValidator.current.message('FirstName', registerForm.PatFirstName, 'required|alpha_space')}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 col-6">
                                                            <div className="form-group -animated">
                                                                <label>Middle Name</label>
                                                                <input onChange={handleInputChange} id="PatMidName" name="PatMidName" value={registerForm.PatMidName} className="form-control" type="text" autoComplete="nope" />
                                                                {personalDetailValidator.current.message('MiddleName', registerForm.PatMidName, 'alpha')}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 col-6">
                                                            <div className={`form-group -animated -active`}>
                                                                <label>Last Name <span className="mandatory">*</span></label>
                                                                <input onChange={handleInputChange} id="PatLastName" disabled={false} name="PatLastName" value={registerForm.PatLastName} className="form-control" type="text" autoComplete="nope" />
                                                                {personalDetailValidator.current.message('LastName', registerForm.PatLastName, 'required|alpha')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-3 col-12">
                                                            <div className="form-group select-dd">
                                                                <label>Religion <span className="mandatory">*</span></label>
                                                                <select onChange={handleInputChange} value={registerForm.ReligionCode} name="ReligionCode" id="ReligionCode" className="form-control custom-select" placeholder={registerForm.ReligionCode ? religionData.find(c => c.code === registerForm.ReligionCode)?.description : "Religion"}>
                                                                    <option hidden>Choose Religion</option>
                                                                    {religionData.length 
                                                                    ? religionData.map(religion => <option key={religion?.code} value={religion?.code}>{religion?.description}</option>)
                                                                    : null}
                                                                </select>
                                                                {personalDetailValidator.current.message('Religion', registerForm.ReligionCode, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-12">
                                                            <div className={`form-group -animated -active`}>
                                                                <label>Email Address <span className="mandatory">*</span></label>
                                                                <input
                                                                    onChange={handleInputChange}
                                                                    name="Email"
                                                                    disabled={false}
                                                                    value={registerForm.Email}
                                                                    className="form-control"
                                                                    type="text"
                                                                    autoComplete="nope"
                                                                    />
                                                                {personalDetailValidator.current.message('Email', registerForm.Email, 'required|email')}
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-6">
                                                            <div className={`form-group -animated ${formData.date_of_diagnosis !== null ? "-active" : ""}`} id="date-diagnosis">
                                                                <label>Date of Diagnosis <span className="mandatory">*</span></label>
                                                                <DatePicker selected={formData.date_of_diagnosis} onChange={(date) => setFormData({ ...formData, date_of_diagnosis: date })} name="date_of_diagnosis" className="form-control" id="forDate" ref={dateRef} />
                                                            </div>
                                                        </div> */}
                                                        <div className="col-md-3">
                                                            <div className={`form-group select-dd -animated ${registerForm.Dob !== null ? "-active" : ""}`}>
                                                                <label>Date of Birth <span className="mandatory">*</span></label>
                                                                <div>
                                                                    <DatePicker 
                                                                        className="form-control"
                                                                        selected={registerForm?.birthDate} 
                                                                        dateFormat="dd/MM/yyyy"
                                                                        showYearDropdown
                                                                        scrollableYearDropdown
                                                                        yearDropdownItemNumber={100}
                                                                        maxDate={new Date()}
                                                                        minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 99))}
                                                                        onChange={async (date) => {
                                                                            if(date) {
                                                                                let dob = `${date.getDate() < 10 ? '0'+date.getDate() : date.getDate()}/${date.getMonth() < 9 ? '0'+(Number(date.getMonth())+1) : Number(date.getMonth())+1}/${date.getFullYear()}`
                                                                                let age = await calculateAge(date.toLocaleDateString())
                                                                                if(dob?.length) {
                                                                                    setRegisterForm({
                                                                                        ...registerForm,
                                                                                        ['Dob']: dob,
                                                                                        ['Age']: age,
                                                                                        ['birthDate']: date
                                                                                    });
                                                                                }
                                                                            }
                                                                        }} 
                                                                    />
                                                                </div>
                                                                {personalDetailValidator.current.message('Dob', registerForm.Dob, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 col-6">
                                                            <div className={`form-group -animated select-disabled ${registerForm.Age >= 0 ? '-active' : ''}`}>
                                                                <label>Age (Years)</label>
                                                                <input onChange={() => {}} id="Age" name="Age" disabled={true} value={registerForm.Age} className="form-control" type="text" autoComplete="nope" />
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group -animated">
                                                        <label>Occupation</label>
                                                        <input onChange={handleInputChange} id="Profession" name="Profession" value={registerForm.Profession} className="form-control" type="text" autoComplete="nope" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group -animated">
                                                        <label>Pan No.</label>
                                                        <input onChange={handleInputChange} id="PanNo" name="PanNo" value={registerForm.PanNo} className="form-control" type="text" autoComplete="nope" />
                                                        {personalDetailValidator.current.message('PanNo', registerForm.PanNo, 'validatePan')}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group -animated">
                                                        <label>Aadhar No.</label>
                                                        <input onChange={handleInputChange} id="AadhaarNo" name="AadhaarNo" value={registerForm.AadhaarNo} className="form-control" type="text" autoComplete="nope" />
                                                        {personalDetailValidator.current.message('AadhaarNo', registerForm.AadhaarNo, 'validateAadhar')}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group radio-group">
                                                        <label className="radio-main-label">Gender <span className="mandatory">*</span></label>
                                                        <div className="row">
                                                            <div className="col-md-3 col-6">
                                                                <div className="custom-control custom-radio">
                                                                    <input onChange={handleInputChange} value="M" type="radio" name="Gender" id="M1"/>
                                                                    <label htmlFor="M1">Male</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3 col-6">
                                                                <div className="custom-control custom-radio">
                                                                    <input onChange={handleInputChange} value="F" type="radio" name="Gender" id="F"/>
                                                                    <label htmlFor="F">Female</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3 col-6">
                                                                <div className="custom-control custom-radio">
                                                                    <input onChange={handleInputChange} value="O" type="radio" name="Gender" id="O"/>
                                                                    <label htmlFor="O">Other</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {personalDetailValidator.current.message('Gender', registerForm.Gender, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group radio-group">
                                                        <label className="radio-main-label">Marital Status <span className="mandatory">*</span></label>
                                                        <div className="row">
                                                            {maritalStatusData?.length 
                                                            ? maritalStatusData.map((maritalStatus,i) => {
                                                                return (
                                                                    <div className="col-md-4 col-lg-3" key={i}>
                                                                        <div className="custom-control custom-radio">
                                                                            <input onChange={handleInputChange} value={maritalStatus?.code} type="radio" name="MaritalStatus" id={maritalStatus?.code} />
                                                                            <label for={maritalStatus?.code}> {maritalStatus?.description}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            : null}
                                                        </div>
                                                        {maritalStatusData?.length ? personalDetailValidator.current.message('MaritalStatus', registerForm.MaritalStatus, 'required') : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`detail_wrap form_block ${contactDetailsValidated ? "active" : ""}`}>
                                        <h3>Contact Details</h3>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group -animated">
                                                    <label>Address 1<span className="mandatory">*</span></label>
                                                    <input onChange={handleContactChange} value={contact.PatAdd_1} name="PatAdd_1" className="form-control" type="text" autoComplete="nope" />
                                                    {contactDetailValidator.current.message('Address', contact.PatAdd_1, 'required')}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group -animated">
                                                    <label>Address 2</label>
                                                    <input onChange={handleContactChange} value={contact.PatAdd_2} name="PatAdd_2" className="form-control" type="text" autoComplete="nope" />
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <div className={`form-group select-dd ${appointment?.Country ? 'select-disabled' : ''}`}>
                                                    <label>Country <span className="mandatory">*</span></label>
                                                    <select onChange={handleContactChange} value={contact.Country} name="Country" id="" className="form-control custom-select" placeholder={contact.Country ? countryData.find(c => c.country_code === contact.Country)?.country_name : "Select Country"}>
                                                        <option hidden>Choose Country</option>
                                                        {countryData?.length 
                                                        ? countryData.map(country => <option key={country?.country_code} value={country?.country_code}>{country?.country_name}</option>) 
                                                        : null}
                                                    </select>
                                                    {contactDetailValidator.current.message('Country', contact.Country, 'required')}
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <div className={`form-group select-dd ${disableStates ? 'select-disabled' : ''}`}>
                                                    <label>State <span className="mandatory">*</span></label>
                                                    <select onChange={handleContactChange} value={contact.State} name="State" id="" className="form-control custom-select" placeholder="State">
                                                        <option hidden>Choose State</option>
                                                        {stateData?.length 
                                                        ? stateData.map(state => <option key={state?.state_des} value={state?.state_des}>{state?.state_des}</option>) 
                                                        : null}
                                                    </select>
                                                    {contactDetailValidator.current.message('State', contact.State, 'required')}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group -animated">
                                                    <label>City/Town </label>
                                                    <input onChange={handleContactChange} value={contact.City} name="City" className="form-control" type="text" autoComplete="nope" />
                                                    {/* {contactDetailValidator.current.message('City', contact.City, 'required')} */}
                                                </div>
                                            </div>
                                            
                                            <div className="col-md-3 col-6">
                                                <div className="form-group -animated">
                                                    <label>Pincode </label>
                                                    <input onChange={handleContactChange} value={contact.PostBoxNo} name="PostBoxNo" className="form-control" type="text" autoComplete="nope" />
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <div className="form-group select-dd">
                                                    <label>Nationality <span className="mandatory">*</span></label>
                                                    <select onChange={handleContactChange} value={contact.NationalityCode} name="NationalityCode" id="" className="form-control custom-select" placeholder="Nationality">
                                                        <option hidden>Choose Nationality</option>
                                                        {countryData?.length 
                                                        ? countryData.map(country => <option key={country?.country_code} value={country?.country_code}>{country?.country_name}</option>) 
                                                        : null}
                                                    </select>
                                                    {contactDetailValidator.current.message('Nationality', contact.NationalityCode, 'required')}
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-6"></div>
                                            </div>
                                            <div className="row otp_row">
                                                <div className="col-md-3 col-6">
                                                    <div className={`form-group -animated -active`}> 
                                                    {/* ${appointment?.Mobile ? '-active select-disabled' : ''} */}
                                                        <label>Mobile 1 <span className="mandatory">*</span></label>
                                                        <input onChange={handleContactChange} disabled={false} value={contact.Mobile} name="Mobile" className="form-control" type="text" autoComplete="nope" />
                                                        {mobileValidator.current.message('Mobile', contact.Mobile, 'required')}
                                                    </div>
                                                    {!appointment?.Mobile
                                                        ? <>
                                                            {!verifyOpen 
                                                                ? <LinkButton title={`Verify with OTP`} onBtnClick={onVerify} loading={otpLoading} />
                                                                : <div className="position-relative">
                                                                    <div className={`form-group -animated -focus ${appointment?.otp ? '-active select-disabled' : ''}`}>
                                                                        <label>Enter OTP <span className="mandatory">*</span></label>
                                                                        <input className="form-control" type="text" disabled={appointment?.otp} name="OTP" value={contact.OTP} onChange={handleContactChange} autoComplete="nope"/>
                                                                        {otpValidator.current.message('OTP', contact.OTP, 'required')}
                                                                    </div>
                                                                    {!appointment?.otp
                                                                    ? <LinkButton title={`Resend OTP`} resend onBtnClick={onResendOTP} loading={otpLoading} />
                                                                    : null}
                                                                </div>
                                                            }
                                                        </>
                                                        : null}
                                                </div>                
                                            </div>
                                            
                                            <div className="row">
                                                <div className="col-md-3 col-6">
                                                    <div className="form-group -animated">
                                                        <label>Tel. Number (Resi.)</label>
                                                        <input onChange={handleContactChange} value={contact.Tele_resident} name="Tele_resident" className="form-control" type="text" autoComplete="nope" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-6">
                                                    <div className="form-group -animated">
                                                        <label>Tel. Number (Office)</label>
                                                        <input onChange={handleContactChange} value={contact.Tele_work} name="Tele_work" className="form-control" type="text" autoComplete="nope" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row row align-items-center">
                                                        <div className="col-lg-4 col-md-4 col-12">
                                                            <div className="form-group -animated">
                                                                <label>ABHA Number (if available)</label>
                                                                <input onChange={handleContactChange} value={contact.ABHA_ID} name="ABHA_ID" className="form-control" type="text" autoComplete="nope" />
                                                                {contactDetailValidator.current.message('AbhaId', contact.ABHA_ID, 'min:14|max:14')}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8 col-md-6 col-12">
                                                            <div className="btn_wrap mt-0 mb-4 mb-md-0">
                                                                <a href="https://abha.abdm.gov.in/abha/v3" className="btn btn btn-primary-solid text-capitalize simpleBtn" target="_blank">Know About &amp; Create ABHA Number</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <div className="accordion_container form_block">
                                        <div id="accordion" role="tablist" className="common_accordion">
                                            <div className="card">
                                                <div className="card-header" role="tab" id="headingOne">
                                                    <h5 className="mb-0">
                                                        <a data-toggle="collapse" data-target='ResponsiblePersonDetails' href="#ResponsiblePersonDetails" aria-expanded="true" aria-controls="ResponsiblePersonDetails">
                                                        Responsible Person/Kin Details
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="ResponsiblePersonDetails" className="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-md-3 col-12">
                                                                        <div className="form-group select-dd">
                                                                            <label>Relation <span className="mandatory">*</span></label>
                                                                            <select onChange={handleKinChange} value={kinDetails.KinRelationCode} name="KinRelationCode" id="KinRelationCode" className="form-control custom-select" placeholder="Select Relation">
                                                                                {relationData.length 
                                                                                ? relationData.map(relation => <option key={relation?.code} value={relation?.code}>{relation?.description}</option>)
                                                                                : null}
                                                                            </select>
                                                                            {kinDetailValidator.current.message('Relation', kinDetails.KinRelationCode, 'required')}
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="col-md-2 col-6">
                                                                        <div className="form-group select-dd">
                                                                            <label>Title</label>
                                                                            <select value={kinDetails.KinTitle} onChange={handleKinChange} name="title" id="title" className="form-control custom-select" placeholder="Choose Title">
                                                                                <option value="Mr">Mr.</option>
                                                                                <option value="Miss">Miss.</option>
                                                                                <option value="Dr">Dr.</option>
                                                                            </select>
                                                                        </div>
                                                                    </div> */}
                                                                    <div className="col-md-3 col-12">
                                                                        <div className="form-group -animated">
                                                                            <label>First Name <span className="mandatory">*</span></label>
                                                                            <input
                                                                                onChange={handleKinChange}
                                                                                id="KinFirstName"
                                                                                name="KinFirstName"
                                                                                value={kinDetails.KinFirstName}
                                                                                className="form-control"
                                                                                type="text"
                                                                                autoComplete="nope"
                                                                            />
                                                                            {kinDetailValidator.current.message('FirstName', kinDetails.KinFirstName, 'required|alpha')}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 col-12">
                                                                        <div className="form-group -animated">
                                                                            <label>Middle Name</label>
                                                                            <input
                                                                                onChange={handleKinChange}
                                                                                id="KinMidName"
                                                                                name="KinMidName"
                                                                                value={kinDetails.KinMidName}
                                                                                className="form-control"
                                                                                type="text"
                                                                                autoComplete="nope"
                                                                            />
                                                                            {kinDetailValidator.current.message('MiddleName', kinDetails.KinMidName, 'alpha')}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3 col-12">
                                                                        <div className="form-group -animated">
                                                                            <label>Last Name</label>
                                                                            <input
                                                                                onChange={handleKinChange}
                                                                                id="KinLastName"
                                                                                name="KinLastName"
                                                                                value={kinDetails.KinLastName}
                                                                                className="form-control"
                                                                                type="text"
                                                                                autoComplete="nope"
                                                                            />
                                                                            {kinDetailValidator.current.message('LastName', kinDetails.KinLastName, 'alpha')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-md-3 col-12">
                                                                        <div className="form-group -animated">
                                                                            <label>Contact No. <span className="mandatory">*</span></label>
                                                                            <input onChange={handleKinChange} value={kinDetails.KinMobile} name="KinMobile" className="form-control" type="text" autoComplete="nope" />
                                                                            {kinDetailValidator.current.message('ContactNo', kinDetails.KinMobile, 'required|min:10|max:10|validateMobile')}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3 col-12">
                                                                        <div className="form-group -animated">
                                                                            <label>Tel Resident No. </label>
                                                                            <input onChange={handleKinChange} value={kinDetails.KinTelResident} name="KinTelResident" className="form-control" type="text" autoComplete="nope" />
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="col-md-6 col-12">
                                                                        <div className="form-group -animated">
                                                                            <label>Doctors Details </label>
                                                                            <input onChange={handleKinChange} name="DoctorDetails" value={kinDetails.DoctorDetails} className="form-control" type="text" />
                                                                            {kinDetailValidator.current.message('DoctorDetails', kinDetails.DoctorDetails, 'required')}
                                                                        </div>
                                                                    </div> */}
                                                                    <div className="col-md-4 col-12">
                                                                        <div className="form-group -animated">
                                                                            <label>Doctors Name  <span className="mandatory">*</span></label>
                                                                            <input onChange={handleKinChange} name="DoctorName" value={kinDetails.DoctorName} className="form-control" type="text" autoComplete="nope" />
                                                                            {kinDetailValidator.current.message('DoctorName', kinDetails.DoctorName, 'required|alpha_space')}
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="col-md-4 col-12">
                                                                        <div className="form-group -animated">
                                                                            <label>Refering doctor's name  </label>
                                                                            <input onChange={handleKinChange} name="DoctorRefName" value={kinDetails.DoctorRefName} className="form-control" type="text" />
                                                                        </div>
                                                                    </div> */}
                                                                    <div className="col-md-4 col-12">
                                                                        <div className="form-group -animated">
                                                                            <label>Refering doctors contact no </label>
                                                                            <input onChange={handleKinChange} value={kinDetails.DoctorTelephone} name="DoctorTelephone" className="form-control" type="text" autoComplete="nope" />
                                                                            {kinDetailValidator.current.message('DoctorsContactNo', kinDetails.DoctorTelephone, 'min:10|max:10|validateMobile')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_block consentFormBlock appointmentDetailsWrap mb-4">
                                        <div className="row">
                                          {/* Changes OF Consent Start  */}
                                            <div className="col-md-12">
                                            <h3 className="titleText">General Consent</h3>
                                            <div className="consentRadioSec">
                                                <div className="form-group radio-group">
                                                    <div className="custom-control custom-radio">
                                                    <input type="radio" name="consent"  id="english" value="english"
                                                        onChange={() => setLang('english')}
                                                        checked={lang === 'english' ? true : false}
                                                    />
                                                    <label for="english"> English</label>
                                                    </div>
                                                    <div className="custom-control custom-radio">
                                                    <input type="radio" name="consent" id="marathi" value="marathi" 
                                                        onChange={() => setLang('marathi')}
                                                        checked={lang === 'marathi' ? true : false}
                                                    />
                                                    <label for="marathi">Marathi</label>
                                                    </div>
                                                    <div className="custom-control custom-radio">
                                                    <input type="radio" name="consent" id="hindi" value="hindi" 
                                                        onChange={() => setLang('hindi')}
                                                        checked={lang === 'hindi' ? true : false}
                                                    />
                                                    <label for="hindi"> Hindi</label>
                                                    </div>
                                                    <div className="custom-control custom-radio">
                                                    <input type="radio" name="consent" id="gujrati" value="gujrati" 
                                                        onChange={() => setLang('gujrati')}
                                                        checked={lang === 'gujrati' ? true : false}
                                                    />
                                                    <label for="gujrati"> Gujrati</label>
                                                    </div>
                                                </div>   
                                            </div>
                                            <div className={`languageBox english ${lang === 'english' ? '' : 'formNoteSec'}`}>
                                                <ol className="mb-0">
                                                    <li>I/We agree for the patient to undergo examination,investigation & treatment as decided by the hospital & also to abide by its schedule of charges,rules & regulation.(available at registration counter)</li>
                                                    <li>I authorize Mr./Ms.______________________________________ to take decision on my behalf in case of inability to do so due to associated medical condition.</li>
                                                    <li>I understand that I have disclosed my clinical history & other relevant information to the healthcare provider team required for the management of my disease.</li>
                                                    <li>I am fully aware that the medical teatment may be extended beyond beyond the expected period at the discretion of the doctor.</li>
                                                    <li>I understand my medical record will be destroyed 3 years after my last visit to the hospital.</li>
                                                    <li>If my financial credit status is disputed by credit/insurance company /TPA. I undertake to settle the final bill on the date of discharge. I also understand to make the payment against interim bills raised within stipulated time.</li>
                                                    <li>I certify that I have read the above contents and understood it. I further state that I have given an opportunity to ask questions which have been answered fully and to my satisfaction.</li>
                                                    <li>By submitting above details, you are authorising Hinduja Hospital to call you & send transaction, promotional communication even though you may be registered under DNC.</li>
                                                </ol>
                                            </div>
                                            <div className={`languageBox marathi ${lang === 'marathi' ? '' : 'formNoteSec'}`}>
                                                <img className="languageConsentImage" src="/assets/images/consent/marathi-consent-image.png" alt="Marathi Consent" />
                                            </div>
                                            <div className={`languageBox hindi ${lang === 'hindi' ? '' : 'formNoteSec'}`}>
                                                <img className="languageConsentImage" src="/assets/images/consent/hindi-consent-image.png" alt="Hindi Consent" />
                                            </div>
                                            <div className={`languageBox gujrati ${lang === 'gujrati' ? '' : 'formNoteSec'}`}>
                                                <img className="languageConsentImage" src="/assets/images/consent/gujrati-consent-image.png" alt="Gujrati Consent" />
                                            </div>
                                          </div>
                                    </div>  
                                    {/* Changes OF Consent End  */}      
                                </div>  
                                    <div className="form_block">
                                        <div className="checkbox_container">
                                            <div className="form-group checkbox-group">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="custom-checkbox">
                                                            <input defaultChecked={checkbox.all_tc} onChange={handleCheckbox} name="all_tc" type="checkbox" id="all_tc"/>
                                                            <label for="all_tc">I accept all the<a target="_blank" href="/administrative-terms-conditions"> terms & conditions.</a></label>
                                                        </div>
                                                        <div className="custom-checkbox">
                                                            <input defaultChecked={checkbox.agree} onChange={handleCheckbox} name="agree" type="checkbox" id="agree"/>
                                                            <label for="agree">I/We agree for the patient to undergo examination, investigation & treatment as decided by the hospital & also to abide by its schedule of charges, rules & regulation.</label>
                                                        </div>
                                                        <div className="custom-checkbox">
                                                            <input defaultChecked={checkbox.autorize} onChange={handleCheckbox} name="autorize" type="checkbox" id="autorize"/>
                                                            <label for="autorize">I authorise Mr./Ms to take decision on my behalf in case of my inability to do so due to associated medical conditions.</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_block">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group upload-file -animated mb-2">
                                                    <label>Upload Gov Id Proof. <span className="mandatory">*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" readonly />
                                                        <div className="input-group-btn">
                                                            <span className="fileUpload">
                                                                <span className="upl upload" id="upload">{aadharImg ? 'Uploaded' : 'Upload'} </span>
                                                                {aadharImg ? <a className="remove otp_color" id="upload" onClick={() => removeImg()}>Remove</a> : null}
                                                                <input id='reg_img_upload' name="resume" className="form-control" type="file" onChange={handleImgUpload} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {personalDetailValidator.current.message('IdentificationProofImage', aadharImg, 'required')}
                                                {fileError.length > 0 ? <p className="field_error">{fileError}</p> : ""}
                                            </div>
                                        </div>
                                        {aadharImg?.name 
                                            ? <div className="notes border-0 mb-2">
                                                <ul>
                                                    <li>{aadharImg?.name}</li>
                                                </ul>
                                            </div>
                                            : null}
                                        <div className="row">
                                            <div className="col-md-10">
                                                <div className="notes">
                                                    <ul>
                                                        <li>Make sure that:</li>
                                                        <li>1. The image is not blurry.</li>
                                                        <li>2. All the details are clearly visible.</li>
                                                        <li>3. Image should not exceed 5 MB.</li>
                                                        <li>4. Only .jpg .png .pdf format permitted.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {location?.state?.apiData && <PaymentBilling setTotal={setTotal} apiData={location?.state?.apiData} formData={location?.state?.formData}/>}
                                    <div className="form_block">
                                        

                                        <APIButton 
                                            title={`Continue`} 
                                            loading={loading}  
                                            onBtnClick={(e) => !loading ? submitRegistrationForm(e) : {}}  
                                            className={`btn btn-primary`} 
                                        />
                                    </div>
                                </div>
                                <div className="tankyou_div" style={{display: "none"}}>
                                <div className="check_mark">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="49.078" height="49.354" viewBox="0 0 49.078 49.354">
                                        <g id="Group_2657" data-name="Group 2657" transform="translate(0)">
                                            <path id="Path_3040" data-name="Path 3040" d="M52.791,24.176l-1.036-.709A3.209,3.209,0,0,1,50.5,19.924l.327-1.2a3.26,3.26,0,0,0-2.617-4.089l-1.254-.218A3.182,3.182,0,0,1,44.287,11.8l-.218-1.254A3.228,3.228,0,0,0,39.98,7.986l-1.2.382a3.267,3.267,0,0,1-3.543-1.2l-.763-1.09a3.215,3.215,0,0,0-4.8-.491l-.927.872a3.207,3.207,0,0,1-3.761.436L23.9,6.3a3.278,3.278,0,0,0-4.579,1.635l-.491,1.2a3.13,3.13,0,0,1-3.162,2.017l-1.254-.055a3.191,3.191,0,0,0-3.38,3.434l.109,1.254A3.275,3.275,0,0,1,9.18,19l-1.145.491a3.257,3.257,0,0,0-1.581,4.579l.654,1.09a3.207,3.207,0,0,1-.382,3.761l-.818.927a3.181,3.181,0,0,0,.6,4.8l1.036.709A3.209,3.209,0,0,1,8.8,38.9l-.491,1.254a3.26,3.26,0,0,0,2.617,4.089l1.254.218a3.182,3.182,0,0,1,2.671,2.617l.218,1.254a3.228,3.228,0,0,0,4.089,2.562l1.2-.382a3.267,3.267,0,0,1,3.543,1.2l.763,1.036a3.215,3.215,0,0,0,4.8.491l.927-.872a3.207,3.207,0,0,1,3.761-.436l1.09.6a3.278,3.278,0,0,0,4.579-1.635l.491-1.2a3.129,3.129,0,0,1,3.162-2.017l1.254.055a3.191,3.191,0,0,0,3.38-3.434l-.055-1.254a3.275,3.275,0,0,1,1.963-3.216l1.145-.491a3.257,3.257,0,0,0,1.581-4.579l-.654-1.09a3.207,3.207,0,0,1,.382-3.761l.818-.927A3.172,3.172,0,0,0,52.791,24.176Z" transform="translate(-5.081 -4.733)" fill="#33a470"/>
                                            <g id="Group_2463" data-name="Group 2463" transform="translate(14.719 18.535)">
                                                <line id="Line_241" data-name="Line 241" x2="6.375" y2="6.375" transform="translate(0 6.375)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4"/>
                                                <line id="Line_242" data-name="Line 242" y1="12.75" x2="13.388" transform="translate(6.375)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="thankyou_content">
                                    <p>Thank you <span>Krishna Chandaluri</span> for sharing your contact details.</p>
                                    <p>Your HH number is 23234321.</p>
                                </div>
                                <div className="btn_wrap">
                                    <button className="btn btn-primary">Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                </div>
                </div>
            </section>
            <Modal
                show={showModal}
                animation={false}
                centered
                size="lg"
                dialogClassName="modal common_modal pay_modal modal-center"
            >
                <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <Modal.Header>
                    <h2>PAYMENT CONFIRMATION</h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="pay_terms_wrap appointmentDetailsWrap">
                        <div>
                            <p>Dear Patron, Kindly confirm the patients name entered in the registation form.</p>
                            <br/> 
                             <div className="row paymentcnfWrap">
                                <div className="col-md-12">
                                    <div className="detailsBox">
                                        <span className="leftText">First Name</span><span className="rightText">: {registerForm?.PatFirstName}</span>
                                    </div>
                                </div>  
                                <div className="col-md-12">
                                    <div className="detailsBox">
                                        <span className="leftText">Middle Name</span><span className="rightText">: {registerForm?.PatMidName || ''}</span>
                                    </div>
                                </div>  
                                <div className="col-md-12">
                                    <div className="detailsBox">
                                        <span className="leftText">Last Name</span><span className="rightText">: {registerForm?.PatLastName}</span>
                                    </div>
                                </div>  
                            </div>   
                            <br/>
                           
                            {location?.state?.apiData && 
                                <PaymentBilling 
                                    setTotal={setTotal} 
                                    apiData={location?.state?.apiData} 
                                    formData={location?.state?.formData}
                                />}

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={handlePayment} >Accept & Pay</button>
                </Modal.Footer>
            </Modal>
        </Layout>
    </>
    )
}

export default Register